<template>
  <v-row class="files-section">
    <v-col
      cols="12"
      sm="4"
    >
      <base-material-card
        color="secondary"
        title="Categories"
      >
        <v-expansion-panels
          accordion
          flat
        >
          <v-expansion-panel
            v-for="(section, i) in sections"
            :key="i"
          >
            <v-expansion-panel-header
              class="doc-expansion-header no-down-icon"
              @click="getFiles(section)"
            >
              <div>
                <v-icon left>
                  {{ section.icon }}
                </v-icon>
                {{ section.title }}
              </div>
            </v-expansion-panel-header>
          </v-expansion-panel>
        </v-expansion-panels>
      </base-material-card>
    </v-col>

    <v-col
      cols="12"
      sm="8"
    >
      <base-material-card color="primary">
        <template v-slot:heading>
          <div class="display-1 font-weight-light">
            Class Files
          </div>
          <div class="subtitle-1">
            {{ directory.title || 'Select a tab to browse files' }}
          </div>
        </template>

        <template v-if="directory.title">
          <v-progress-linear
            v-if="uploadingProgress"
            :value="uploadingProgress"
            height="15"
            color="success"
            striped
          />
          <vue-dropzone
            v-if="role && checkCUD(role.id)"
            :id="directory.code"
            ref="dropzone"
            :options="dropOptions"
            :destroy-dropzone="true"
            @vdropzone-success="fileUploaded"
            @vdropzone-total-upload-progress="checkUploadProgress"
          />
        </template>
        <v-card-text
          v-else
          class="text-center"
        >
          <v-icon
            size="100"
            color="grey"
          >
            mdi-source-repository-multiple
          </v-icon>

          <h4 class="display-2 font-weight-light mb-3 black--text">
            Class Name
          </h4>
          <h4 class="display-1 font-weight-light mb-3 black--text">
            Company Name
          </h4>
        </v-card-text>
      </base-material-card>
    </v-col>
  </v-row>
</template>

<script>
  import vueDropzone from 'vue2-dropzone'
  import { mapActions, mapState } from 'vuex'
  import { apiUrl } from '../../../../../config'
  import { checkCUD } from '@/shared/management'

  export default {
    components: { vueDropzone },
    data: () => ({
      sections: [
        { title: 'Fire Plans', icon: 'mdi-fire-extinguisher', code: 'fire_plans' },
        { title: 'Drawings', icon: 'mdi-file-pdf', code: 'drawings' },
        { title: 'Models', icon: 'mdi-laptop', code: 'models' },
      ],
      directory: {},
      checkCUD,
      dropOptions: {
        url: '',
        headers: {},
        dictDefaultMessage: '<b>Drag and drop to upload content</b><br/> or click to select a file from your computer.<br/>Maximum 5 files upload at a time. Maximum per file upload size 1024mb.',
        timeout: 18000000,
        maxFilesize: 1024,
        parallelUploads: 5,
      },
      uploadingProgress: 0,
    }),

    computed: {
      ...mapState({
        role: state => state.authentication.role,
      }),
    },

    watch: {
      directory (newVal, oldVal) {
        if (this.$refs.dropzone) {
          this.$refs.dropzone.setOption('url', `${apiUrl}users/${this.$route.params.id}/files/documents/${newVal.code}/upload`)
          this.$refs.dropzone.setOption('headers', { Authorization: 'Bearer ' + this.$store.state.authentication.token })
        } else {
          this.dropOptions.url = `${apiUrl}users/${this.$route.params.id}/files/documents/${newVal.code}/upload`
          this.dropOptions.headers = { Authorization: 'Bearer ' + this.$store.state.authentication.token }
        }
      },
    },

    methods: {
      ...mapActions({
        showSnackBar: 'showSnackBar',
      }),

      getFiles (section) {
        this.directory = section
      },

      fileUploaded (file, res) {
        this.$refs.dropzone.removeAllFiles()
        this.showSnackBar({ text: res.message, color: 'success' })
      },

      checkUploadProgress (progress) {
        this.uploadingProgress = progress
        if (progress === 100) {
          this.uploadingProgress = 0
        }
      },
    },
  }
</script>

<style lang="sass">
  .doc-expansion-header
    font-size: 16px !important
  .no-down-icon
    .v-expansion-panel-header__icon
      display: none
</style>
